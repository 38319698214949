import { useEffect, useState } from "react";
import { createTheme, Grid, ThemeProvider, useMediaQuery } from "@mui/material";
import * as S from "styles/styles";
import FooterSite from "./FooterSite";
import TopoSiteSemLogo from "./TopoSite";
import FooterCalendarioRanger from "./FooterCalendario/FooterCalendarioRanger";
import WhatsappContato from "./WhatsappContato";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#66d7d5",
      light: "#D8F4F3",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#f0851b",
    },
  },
});

export default function LayoutPadraoSite(props: any) {
  const [ativoScroll, setAtivoScroll] = useState(false);
  const [ativaReserva, setAtivaReserva] = useState(false);
  const larguraTela = useMediaQuery("(min-width:700px)");

  useEffect(function () {
    function posicaoScroll() {
      if (window.scrollY > 1) {
        setAtivoScroll(true);
      } else {
        setAtivoScroll(false);
      }
    }

    window.addEventListener("scroll", posicaoScroll);
  }, []);

  async function ativaMenuReserva() {
    window.scroll({ top: larguraTela ? 475 : 390, behavior: "smooth" });
    setAtivaReserva(!ativaReserva);
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <GoogleTranslate /> */}
      <WhatsappContato />
      <S.divRelative>
        <Grid container justifyContent={"center"}>
          <TopoSiteSemLogo />
        </Grid>
      </S.divRelative>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <Grid
          container
          justifyContent={"center"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {props.children}
        </Grid>
      </Grid>
      <FooterSite />
      <FooterCalendarioRanger />
    </ThemeProvider>
  );
}
